import React from "react";
import LoadingOverlay from "../overlay";
import { useEstimatedPrice } from "../../hooks/admin/useEstimatedPrice";
import { Box } from "@material-ui/core";
import RailguardGallery from "../picker/railguard-gallery";
import ParamsPicker from "../picker/params-picker";
import PriceDisplay from "../picker/price-display";
import { useGuardrailTypes } from "../../hooks/useGuardrailTypes";
import { useAdmin } from "./useAdmin";
import { FormikProvider } from "formik";
import PricingForm from "./pricing/pricingForm";
import { usePricingForm } from "./pricing/usePricingForm.hooks";

const AdminWrapper = ({ pricingConfig, onSave }) => {
    const {
        selectType,
        setSelectedValues,
        state: { selectedType, selectedValues }
    } = useAdmin();
    const { railguardTypes, guardrailImageByType } = useGuardrailTypes();
    const { formik, selectedPricingConfig, isSelectedPricingConfigValid } = usePricingForm({ pricingConfig, onSave });
    const { price, isLoading: isPriceLoading } = useEstimatedPrice({
        railguardType: selectedType,
        selectedValues,
        isSelectedPricingConfigValid,
        newPricingConfig: selectedPricingConfig
    });

    const shouldShowLoadingOverlay = isPriceLoading;

    if (!pricingConfig) {
        return null;
    }

    return (
        <>
            <LoadingOverlay open={shouldShowLoadingOverlay} />
            <FormikProvider value={formik}>
                <PricingForm formik={formik} />
            </FormikProvider>
            <Box pb={3} pt={3}>
                <h1>Próbnik cen</h1>
                <p>Poniżej można przetestować nowy cennik. Po zmianie dowolnej ceny powyżej, cena wybranej poniżej odbojnicy powinna się automatycznie zaktualizować. Zmiany cennika wchodzą w życie dopiero po kliknięciu przycisku "Zapisz cennik" powyżej.</p>    
                <h4>Wybierz typ odbojnicy</h4>
                <RailguardGallery
                    guardrailImageByType={guardrailImageByType}
                    railguardTypes={Object.keys(railguardTypes)}
                    selectedType={selectedType}
                    onSelect={(type) => selectType(type)}
                />
            </Box>
            {
                selectedType &&
                <>
                    <ParamsPicker
                        railguardType={selectedType}
                        selectedValues={selectedValues}
                        setSelectedValues={(params) => setSelectedValues(params)}
                    />
                    <PriceDisplay price={price} />
                </>
            }

        </>
    )
}

export default AdminWrapper;