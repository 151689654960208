import React from "react"
import SEO from "../../components/seo";
import AdminLayout from "../../components/admin/layout";
import Admin from "../../components/admin/admin";

const IndexPage = () => {
    return (
        <AdminLayout>
            <SEO title="Panel administracyjny" />
            <Admin />
        </AdminLayout>
    )
}

export default IndexPage
