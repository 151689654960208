import { navigate } from "gatsby";
import { useEffect, useState } from "react";
import { AdminClient } from "../../components/admin/admin-client";

export const usePricingConfig = () => {
    const adminClient = AdminClient()

    const [loading, setLoading] = useState(0);
    const [pricingConfig, setPricingConfig] = useState(undefined);

    useEffect(() => {
        const load = async () => {
            setLoading((loading) => loading + 1);
            try {
                const pricingConfig = await adminClient.readPricing()
                setPricingConfig(pricingConfig);
            } catch (error) {
                navigate("/admin/login");
            } finally {
                setLoading((loading) => loading - 1);
            }
        }
        load();
    }, []);

    const saveNewPricingConfig = async ({ newPricingConfig }) => {
        adminClient.updatePricing({ newPricing: newPricingConfig })
    }

    return {
        isLoading: loading > 0,
        pricingConfig,
        saveNewPricingConfig
    }
}