import { useEffect, useState } from "react";
import { callApi } from "../../components/api";

const validator = {
    "d": (value) => value !== undefined,
    "l": (value) => value !== undefined,
    "h": (value) => value !== undefined,
    "depth": (value) => value !== undefined,
    "g": (value) => value !== undefined,
    "p": (value) => value !== undefined && (value === "false" || value !== ""),
    "mm": (value) => value !== undefined,
}

export const useEstimatedPrice = ({ railguardType, selectedValues, newPricingConfig, isSelectedPricingConfigValid }) => {
    const [price, setPrice] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);

    const areValuesForAllParamsProvided = !Array.from(selectedValues.entries()).some(([key, value]) => !validator[key](value));

    useEffect(() => {
        if(!isSelectedPricingConfigValid) {
            setPrice(undefined);
        }
    }, [isSelectedPricingConfigValid]);

    useEffect(() => {
        setPrice(undefined);
    }, [railguardType]);

    useEffect(() => {
        if (!isSelectedPricingConfigValid || selectedValues.size === 0 || !areValuesForAllParamsProvided || !newPricingConfig) {
            return;
        }

        setIsLoading(true);

        const urlParams = Array.from(selectedValues.entries())
            .map(([key, value]) => `${key}=${value}`)
            .join("&");

        const abortApiCall = new AbortController();

        callApi(`/api/admin/pricing/${railguardType}?a=${isSelectedPricingConfigValid}&${urlParams}`, 
            { 
                method: "POST",
                body: JSON.stringify(newPricingConfig),
                signal: abortApiCall.signal,
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then(response => response.json())
            .then(data => {
                const price = data["price"];
                setPrice(price);
            })
            .finally(() => setIsLoading(false));

        return () => {
            abortApiCall.abort();
        }
    }, [isSelectedPricingConfigValid, railguardType, selectedValues, JSON.stringify(newPricingConfig)]);

    return {
        price,
        isLoading
    }
}