import React from "react";
import { usePricingConfig } from "../../hooks/admin/usePricingConfig";
import LoadingOverlay from "../overlay";
import AdminWrapper from "./wrapper";

const Admin = () => {
    const {
        isLoading,
        pricingConfig,
        saveNewPricingConfig
    } = usePricingConfig();

    if(isLoading) {
        return <LoadingOverlay open={true} />
    }

    return (pricingConfig ? <AdminWrapper pricingConfig={pricingConfig} onSave={saveNewPricingConfig} /> : null)
}

export default Admin;