import { Button, Grid, InputAdornment } from "@material-ui/core"
import TextInput from "../../forms/text-input"
import React from "react"
import { railguardTypes } from "../../railguardTypes";
import {useField} from "formik";

const PlnTextInput = (props) => (
    <PricingFormNumberInput
        {...props}
        InputProps={{
            startAdornment: <InputAdornment position="start">PLN</InputAdornment>,
        }}
    />
)

const PricingFormNumberInput = (props) => {
    const [field, meta] = useField(props);

    const differentThanInitialValue = meta.value !== meta.initialValue;

    return (<TextInput
        {...props}
        type={"number"}
        onWheel={event => { event.preventDefault(); event.target.blur(); }}
        inputProps={{
            style: { backgroundColor: differentThanInitialValue ? "yellow" : "inherit" },
        }}
    />
    );
}

const translation = {
    basePlateCost: "Koszt podstawki",
    costToWeldASingleEndOfAnElbow: "Koszt przyspawania jednej krawędzi kolana",
    costToWeldABasePlate: "Koszt przyspawania podstawki",
    costToWeldAVerticalStandDirectlyToPipe: "Koszt przyspawania jednej rury bezpośrednio do drugiej",
    costToPaintASquareMeter: "Koszt pomalowania proszkowego 1 m^2",
    costToGalvanizeAKilogram: "Koszt ocynkowania 1 kg",
    costToWeldACutOffPipeEnding: "Koszt przyspawania czapy na końcu rury",
    cutOffPipeEndingCost: "Koszt czapy",
    costToTransportBatchToCoatingFacility: "Koszt przewiezienia fantów do cynkowni"
}

const PricingForm = ({ formik }) => {
    const onSave = () => {
        const confirmationWord = "martech";

        if(prompt(`Aby zapisać zmiany, wpisz '${confirmationWord}' i kliknij OK.`) == confirmationWord) {
            formik
                .submitForm()
                .then(() => alert("Zmiany zostały zapisane"))
        } else {
            alert("Zmiany nie zostały zapisane.")
        }
    }
    
    return (
        <>
            <Grid container columns={1} spacing={4}>
                <Grid item>
                    <h1>Koszty</h1>
                    <p>Wszystkie ceny poniżej to ceny brutto</p>
                    <Grid container spacing={4} columns={2}>
                        {
                            Object.keys(formik.initialValues.fixedCosts)
                                .map(fixedCost => (
                                    <Grid item key={`fixedCosts.${fixedCost}`} xs={3}>
                                        <PlnTextInput
                                            label={translation[fixedCost]}
                                            name={`fixedCosts.${fixedCost}.amount`}
                                            fullWidth
                                        />
                                    </Grid>)
                                )
                        }
                    </Grid>

                    <h2>Koszty kolan</h2>
                    <Grid container spacing={4} columns={2}>
                        {
                            Object.keys(formik.initialValues.elbowPricing)
                                .map((pipeDiameter) => (
                                    <Grid item key={`elbowPricing.${pipeDiameter}`} xs={3}>
                                        <PlnTextInput
                                            label={`${pipeDiameter.replaceAll("_", ".")} mm`}
                                            name={`elbowPricing.${pipeDiameter}.amount`}
                                            fullWidth
                                        />
                                    </Grid>
                                ))
                        }
                    </Grid>

                    <h2>Koszty rur (za 1 m)</h2>
                    <Grid container spacing={4} columns={2}>
                        {
                            Object.keys(formik.initialValues.pipePricing)
                                .map((pipeDiameter) => (
                                    <Grid item key={`pipePricing.${pipeDiameter}`} xs={3}>
                                        <PlnTextInput
                                            label={`${pipeDiameter.replaceAll("_", ".")} mm`}
                                            name={`pipePricing.${pipeDiameter}.amount`}
                                            fullWidth
                                        />
                                    </Grid>
                                ))
                        }
                    </Grid>

                    <h2>Mnożnik cen</h2>
                    <h3>Przykład</h3>
                    <p>
                        Po obliczeniu bazowej ceny odbojnicy, gdzie uwzględniamy koszty materiałów, robocizny i transportu, mnożymy ją przez ten mnożnik. Jeśli bazowa cena wynosi 100 zł brutto, a mnożnik wynosi 1.2, to ostateczna cena wyniesie 120 zł.
                    </p>
                    <Grid container spacing={4} columns={2}>
                        {
                            Object.keys(formik.initialValues.typePriceMultipliers)
                                .sort()
                                .map((type) => (
                                    <Grid item key={`typePriceMultipliers.${type}`} xs={3}>
                                        <PricingFormNumberInput
                                            label={`${railguardTypes[type].name}`}
                                            name={`typePriceMultipliers.${type}`}
                                            fullWidth
                                        />
                                    </Grid>
                                ))
                        }
                    </Grid>
                </Grid>
                <Grid item>
                    <Button variant={"contained"} disabled={!formik.isValid || formik.isSubmitting} onClick={onSave}>Zapisz cennik</Button>
                    <Button variant={"contained"} onClick={() => formik.resetForm()}>Resetuj</Button>
                </Grid>
            </Grid>
        </>
    )
}

export default PricingForm