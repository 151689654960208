import { useFormik } from "formik";
import { object, number } from 'yup';

const validationMessages = {
    number: "To musi być liczba",
    required: "Pole wymagane",
    positive: "Liczba musi być większa od 0"
}

const asPricingConfig = (formikValues) => ({
    fixedCosts: formikValues.fixedCosts,
    elbowPricing: Object.keys(formikValues.elbowPricing)
        .sort((a, b) => Number(a) - Number(b))
        .reduce(
            (acc, pipeDiameter) => ({
                ...acc,
                [pipeDiameter.replaceAll("_", ".")]: formikValues.elbowPricing[pipeDiameter]
            }), {}),
    pipePricing: Object.keys(formikValues.pipePricing)
        .sort((a, b) => Number(a) - Number(b))
        .reduce(
            (acc, pipeDiameter) => ({
                ...acc,
                [pipeDiameter.replaceAll("_", ".")]: formikValues.pipePricing[pipeDiameter]
            }), {}),
    typePriceMultipliers: formikValues.typePriceMultipliers
})

export const usePricingForm = ({ pricingConfig, onSave }) => {
    const initialValues = {
        fixedCosts: pricingConfig.fixedCosts,
        elbowPricing: Object.keys(pricingConfig.elbowPricing)
            .sort((a, b) => Number(a) - Number(b))
            .reduce(
                (acc, pipeDiameter) => ({
                    ...acc,
                    [pipeDiameter.replaceAll(".", "_")]: pricingConfig.elbowPricing[pipeDiameter]
                }), {}),
        pipePricing: Object.keys(pricingConfig.pipePricing)
            .sort((a, b) => Number(a) - Number(b))
            .reduce(
                (acc, pipeDiameter) => ({
                    ...acc,
                    [pipeDiameter.replaceAll(".", "_")]: pricingConfig.pipePricing[pipeDiameter]
                }), {}),
        typePriceMultipliers: pricingConfig.typePriceMultipliers
    }

    const validationSchema = object({
        fixedCosts: object(
            Object.keys(initialValues.fixedCosts)
                .reduce((acc, key) => ({
                    ...acc,
                    [key]: object({
                        amount: number(validationMessages.number)
                            .positive(validationMessages.positive)
                            .required(validationMessages.required)
                    })
                }), {})),
        elbowPricing: object(
            Object.keys(initialValues.elbowPricing)
                .reduce((acc, pipeDiameter) => ({
                    ...acc,
                    [pipeDiameter]: object({
                        amount: number(validationMessages.number)
                            .positive(validationMessages.positive)
                            .required(validationMessages.required)
                    }),
                }), {})
        ),
        pipePricing: object(
            Object.keys(initialValues.pipePricing)
                .reduce((acc, pipeDiameter) => ({
                    ...acc,
                    [pipeDiameter]: object({
                        amount: number(validationMessages.number)
                            .positive(validationMessages.positive)
                            .required(validationMessages.required)
                    }),
                }), {}),
        ),
        typePriceMultipliers: object(
            Object.keys(initialValues.typePriceMultipliers)
                .reduce((acc, type) => ({
                    ...acc,
                    [type]: number(validationMessages.number)
                        .positive(validationMessages.positive)
                        .required(validationMessages.required),
                }), {}),
        )
    });

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: (values) => onSave({ newPricingConfig: asPricingConfig(values) }),
        validationSchema
    });

    const selectedPricingConfig = asPricingConfig(formik.values)

    return {
        formik,
        selectedPricingConfig,
        isSelectedPricingConfigValid: formik.isValid
    }
}