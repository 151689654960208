import { useReducer } from "react";

const reducer = (state, action) => {
    switch (action.type) {
        case 'selectType':
            return {
                ...state,
                selectedType: action.payload.type,
                selectedValues: new Map()
            };
        case 'setSelectedValues':
            return {
                ...state,
                selectedValues: action.payload.selectedValues
            };
        case 'setNewPricingConfig':
            return {
                ...state,
                newPricingConfig: action.payload.newPricingConfig
            }
        default:
            throw new Error();
    }
}

const initialValues = {
    selectedType: undefined,
    selectedValues: new Map()
};

export const useAdmin = () => {
    const [state, dispatch] = useReducer(reducer, initialValues);

    return {
        selectType: (type) => dispatch({ type: 'selectType', payload: { type } }),
        setSelectedValues: (selectedValues) => dispatch({ type: 'setSelectedValues', payload: { selectedValues } }),
        setNewPricingConfig: (newPricingConfig) => dispatch({ type: 'setNewPricingConfig', payload: { newPricingConfig } }),
        state
    }
}